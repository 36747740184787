// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

// Note for dark mode:
// Uncomment below code and change primary color code to set different primary(i.e. light color of your primary) color in dark mode only.

/*User CSS*/

// [data-bs-theme="dark"] {
//   --bs-primary: #19f032 !important;
//   --bs-primary-rgb: 25, 240, 50 !important; 
//   --bs-link-color:#19f032 !important;
//   --bs-link-hover-color:#0c911c !important;
//   --bs-link-color-rgb:25, 240, 50 !important;
//   --bs-link-hover-color-rgb:12, 145, 28;
//   --bs-nav-pills-link-active-bg: #19f032 !important;

//   .navbar {
//     --bs-navbar-hover-color: var(--#{$prefix}primary);
//     --bs-navbar-active-color: var(--#{$prefix}primary);
//     }
    
//     .navbar-dark {
//       --bs-navbar-hover-color: var(--#{$prefix}primary) !important;
//       --bs-navbar-active-color: var(--#{$prefix}primary) !important;
//     }
//     .nav{
//       --bs-nav-link-hover-color: var(--#{$prefix}primary);
//     }
//     .dropdown-menu {
//       --bs-dropdown-link-hover-color: var(--#{$prefix}primary) !important;
//       --bs-dropdown-link-hover-bg: rgba(var(--#{$prefix}primary-rgb), 0.1) !important;
//       --bs-dropdown-link-active-color: var(--#{$prefix}primary) !important;
//       --bs-dropdown-link-active-bg: rgba(var(--#{$prefix}primary-rgb), 0.1) !important;
//     }
  
//   .btn-primary {
//     --bs-btn-bg: var(--#{$prefix}primary);
//     --bs-btn-border-color: var(--#{$prefix}primary);
//     --bs-btn-hover-bg: var(--#{$prefix}link-hover-color);
//     --bs-btn-hover-border-color: var(--#{$prefix}link-hover-color);
//     --bs-btn-active-bg: var(--#{$prefix}link-hover-color);
//     --bs-btn-active-border-color: var(--#{$prefix}link-hover-color);
//     --bs-btn-disabled-bg: var(--#{$prefix}primary);
//   }
//   .btn-link {
//     --bs-btn-color: var(--#{$prefix}primary);
//     --bs-link-color: var(--#{$prefix}primary);
//     --bs-btn-hover-color: var(--#{$prefix}link-hover-color);
//     --bs-btn-active-color: var(--#{$prefix}link-hover-color);
//   }
//   .btn-outline-primary {
//     --bs-btn-color: var(--#{$prefix}primary);
//     --bs-btn-border-color: var(--#{$prefix}primary);
//     --bs-btn-hover-bg: var(--#{$prefix}primary);
//     --bs-btn-hover-border-color: var(--#{$prefix}primary);
//     --bs-btn-active-bg: var(--#{$prefix}primary);
//     --bs-btn-active-border-color: var(--#{$prefix}primary);
//     --bs-btn-disabled-color: var(--#{$prefix}primary);
//     --bs-btn-disabled-border-color: var(--#{$prefix}primary);
//   }
//   .btn-primary-soft{
//     color: var(--#{$prefix}primary);
//     background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
//     &:hover, &:focus{
//       color: $white;
//       background-color: var(--#{$prefix}primary) !important;
//       border-color: var(--#{$prefix}primary) !important;
//     }
//   }
//   .nav-pills {
//     --bs-nav-pills-link-active-bg: var(--#{$prefix}primary);
//   }
//   .form-control:focus{
//     border-color: var(--#{$prefix}primary);
//   }
//   .text-bg-primary{
//     background-color: rgba(var(--#{$prefix}primary-rgb), 1) !important;
//   }
//   .form-check-input:checked {
//     background-color: var(--#{$prefix}primary);
//     border-color: var(--#{$prefix}primary);
//   }
//   .choices.is-focused .choices__inner{
//     border-color: var(--#{$prefix}primary);
//   }

//   .btn-primary-shadow{
//     background-color: var(--#{$prefix}primary);
//     box-shadow: 0 0 0 10px rgba(var(--#{$prefix}primary-rgb), .4);
//     &:hover ,&.active, &:focus{
//       border-color: var(--#{$prefix}primary);
//     }
//   }
// }



.dark{
    display:none;
}

.btn{
    &:hover{
        color: $white;
        background-color: $black;
        border-color: $black;
    }
    &-custom{
        &-width{
            min-width:150px;
        }
    }
}

.sub-text{
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color:#747579;
}

.brokers{
    position:relative;
    .btn{
        border-radius: 5px;
        padding:8px 16px;
    }
    table{
        min-width:1220px;
    }
    i{
        font-size:24px;
    }
    th,td{
        padding:15px;
    }
    tr:first-child{
        th{
            padding-top: 0;
        }
    }
    tr{
        th:first-child,
        td:first-child{
            padding-left:45px;
        }
        th:last-child,
        td:last-child{
            padding-right:45px;
        }
    }
    th{
        border-bottom:0;
        font-weight: 400;
    }
    td{
        font-size: 21px;
        line-height: 30px;
        color: $gray-800;
        font-weight:500;
    }
    span{
        color:rgba(36, 41, 45, 0.5);
    }
    a{
        font-size: 15px;
        line-height: 23px;
    }
    &-info{
        max-width:180px;
        width:100%;
        &-mobile{
            max-width:100%;
            margin-top: 10px;
        }
        &__value{
            font-weight: 500;
            color: $gray-800;
        }
        a{
            padding-left: 10px;
        }
    }
    &-logo{
        margin-bottom: 8px;
        &-mobile{
            margin-bottom: 0;
            max-width:125px;
            margin-left: 25px;
        }
    }
    &-rate{
        display:flex;
        align-items:center;
        justify-content:space-between;
        &__progress{
            max-width:145px;
            width:100%;
        }
        &__value{
            font-size:15px;
            line-height:23px;
            padding-left: 10px;
        }
        &-mobile{
            margin-top: 10px;
            .brokers{
                &-rate{
                    &__progress{
                        max-width:240px;
                    }
                    &__value{
                        color: $gray-800;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .progress{
        height:12px;
        &-mobile{
            height:8px;
        }
        &-bar{
            border-radius: 7px;
        }
    }
    &__num{
        width:42px;
        height:42px;
        border-radius: 50%;
        background-color: $gray-200;
        font-size: 15px;
        line-height:26px;
        font-weight: 500;
        color: $gray-800;
        flex-shrink:0;
        &-mobile{
            width:30px;
            height:30px;
        }
    }
    &-item{
        padding:25px 20px;
        &-bg{
            background-color: rgba(239, 241, 242, 0.5);
        }
    }
    &-cup,
    &-compare{
        max-width:30px;
        flex-shrink:0;
    }
    &-btns{
        margin-top: 20px;
        &__item{
            width: calc(50% - 5px)
        }
        .btn{
            padding:8px 0;
        }
        &__text{
            color:rgba(116, 117, 121, .6);
            font-size: 14px;
            line-height: 18px;
            margin-top: 6px;
        }
    }
}

.table-striped > tbody > tr:nth-of-type(odd) > *{
    color: $gray-800;
}

.table > :not(caption) > * > *{
    border-width:0;
}

.card{
    &-wrapper{
        padding:0 20px;
    }
    &-broker{
        cursor: pointer;
        padding-top: 35px !important;
        min-height:170px;
        transition: background-color .3s ease-in;
        &:hover{
            background-color:rgba(239, 241, 242, 0.5);
        }
        .icon-xl{
            width:60px;
            height:60px;
            line-height:1;
            font-size:1;
        }
    }
    &-title{
        margin-top: 20px;
    }
    &-header{
        &-custom{
            padding-left:40px!important;
        }
    }
}


/* custom 5-cols items */

.col-xs-equal,
.col-sm-equal,
.col-md-equal,
.col-lg-equal {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-equal {
    width: 20%;
    float: left;
}

[data-bs-theme="dark"] {
    .brokers{
        td{
            color: $white;
        }
        span{
            color: rgba(255,255,255,.6);
        }
        .sub-text{
            color:rgba(255,255,255,.8);
        }
        &-item-bg{
            background-color:rgba(255,255,255,.1);
        }
        &-rate{
            &__value{
                color: $white;
            }
        }
        &-info{
            &__value{
                color: $white;
            }
        }
        &-btns{
            &__text{
                color: $white;
            }
        }
    }
    .light{
        display:none;
    }
    .dark{
        display:block;
    }
}

@media (min-width: 320px) {
    .col-xs-equal {
        width: 100%;
        float: left;
    }
}
@media (min-width: 480px) {
    .col-sm-equal {
        width:50%;
    }
}
@media (min-width: 768px) {
.col-sm-equal {
        width: 30%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-equal {
        width: 30%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-equal {
        width: 20%;
        float: left;
    }
}

@media (max-width:358px){
    .brokers{
        .btn{
            font-size: 12px;
            line-height: 20px;
        }
    }
}